import { useHttpService } from '@/composables/httpService';

import type { IGenericModel } from '@Models/generic.model';
import type { IComplaint, ISingleComplaint } from '@Models/Complaint/complaint.model';
import EComplaintsStatus from '@Enums/complaintsStatus';

// pobiera wszystkie skargi dla danego przedmiotu, jeśli nie podamy itemId pobiera wszystkie skargi danego użytkownika
export function fetchComplaintsService(itemId?: string | number) {
  const { $lkBiddingFetch } = useNuxtApp();
  return $lkBiddingFetch<IComplaint[]>(`/rest/bid/complaints`, {
    method: 'GET',
    query: {
      itemId
    }
  });
}

// pobiera wszystkie skargi dla użytkownika
export function fetchAllComplaintsForUserService() {
  const { $lkBiddingFetch } = useNuxtApp();
  return $lkBiddingFetch<IComplaint[]>('/rest/bid/complaints', {
    method: 'GET'
  });
}

// pobieranie informacji o skardze
export function fetchComplaintService(complaintId: number) {
  const { $lkBiddingFetch } = useNuxtApp();
  return $lkBiddingFetch<IGenericModel<ISingleComplaint>>(`/rest/bid/complaint/${complaintId}`, {
    method: 'GET'
  });
}

// pobieranie załącznika skargi
export function fetchComplaintAttachmentService({
  attachmentId,
  complaintId,
  itemId
}: {
  attachmentId: number;
  complaintId: number;
  itemId: number;
}) {
  const { $lkBiddingFetch } = useNuxtApp();
  return $lkBiddingFetch<ArrayBuffer>('/rest/bid/user-complaint/attachment', {
    method: 'GET',
    responseType: 'arrayBuffer',
    query: {
      attachmentId,
      complaintId,
      itemId
    }
  });
}

// pobieranie skargi w formie załącznika
export function downloadComplaintService(complaintId: number, itemId: number) {
  const { $lkBiddingFetch } = useNuxtApp();

  return $lkBiddingFetch<ArrayBuffer>(`/rest/bid/user-complaint/${complaintId}/printout`, {
    method: 'GET',
    responseType: 'arrayBuffer',
    query: {
      itemId
    }
  });
}

// uwzględnienie skargi lub przekazanie skargi
export function complaintBailiffDecisionService(params: { complaintId: number; status: EComplaintsStatus }) {
  const { $lkBiddingFetch } = useNuxtApp();

  return $lkBiddingFetch<IGenericModel<null, string>>('rest/bid/user-complaint/bailiff-decision', {
    method: 'PATCH',
    body: params
  });
}

export function judgeDecisionService(params: {
  complaintId: number;
  judgeDecision: EComplaintsStatus;
  judgeDecisionDate: string;
}) {
  const { $lkBiddingFetch } = useNuxtApp();

  return $lkBiddingFetch<IGenericModel<null, string>>('rest/bid/user-complaint/judge-decision', {
    method: 'PATCH',
    body: params
  });
}

export function fetchComplaintInfo(params: { itemId: string }) {
  const { useGet } = useHttpService();
  const runtimeConfig = useRuntimeConfig();
  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.biddingServer}/rest/bid/complaint/info?itemId=${params.itemId}`;
  return useGet(url);
}

export async function fetchWaiverForBidder(params: { itemId: number }): Promise<unknown> {
  const { useGet } = useHttpService();
  const runtimeConfig = useRuntimeConfig();
  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.biddingServer}/rest/bid/user-complaint/waiver-info`;
  return useGet(url, params);
}

export function confirmBidderWaive(params: { itemId: number }) {
  const { usePatch } = useHttpService();
  const runtimeConfig = useRuntimeConfig();
  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.biddingServer}/rest/bid/user-complaint/request-waiver`;
  return usePatch(url, params);
}

export function fetchComplaintById(complaintId: string) {
  const { useGet } = useHttpService();
  const runtimeConfig = useRuntimeConfig();
  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.biddingServer}/rest/bid/complaint/${complaintId}`;
  return useGet(url);
}

export function printComplaintById(params: { complaintId: string; itemId: string }): Promise<unknown> {
  const { useGetFile } = useHttpService();
  const runtimeConfig = useRuntimeConfig();
  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.biddingServer}/rest/bid/user-complaint/${params.complaintId}/printout?itemId=${params.itemId}`;
  return useGetFile(url);
}

export function fetchAttachment(params: { attachmentId: number; complaintId: string; itemId: string }) {
  const { useGetFile } = useHttpService();
  const runtimeConfig = useRuntimeConfig();
  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.biddingServer}/rest/bid/user-complaint/attachment?attachmentId=${params.attachmentId}&complaintId=${params.complaintId}&itemId=${params.itemId}`;
  return useGetFile(url);
}

export function bailiffDecision(params: { complaintId: number; status: string }): Promise<unknown> {
  const { usePatch } = useHttpService();
  const runtimeConfig = useRuntimeConfig();
  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.biddingServer}/rest/bid/user-complaint/bailiff-decision`;
  return usePatch(url, params);
}

export function judgeDecision(params: {
  complaintId: number;
  judgeDecision: string;
  judgeDecisionDate: string;
}): Promise<unknown> {
  const { usePatch } = useHttpService();
  const runtimeConfig = useRuntimeConfig();
  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.biddingServer}/rest/bid/user-complaint/judge-decision`;
  return usePatch(url, params);
}

//stare zatwierdzanie zrzeczenia
export function confirmWaiverByBailiff(params: { confirmed: boolean; itemId: number }) {
  const { usePatch } = useHttpService();
  const runtimeConfig = useRuntimeConfig();
  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.biddingServer}/rest/bid/user-complaint/confirm-waiver`;
  return usePatch(url, params);
}

export function addBailiffComplaint(params: any): Promise<any | unknown> {
  const { usePostForFormData } = useHttpService();
  const runtimeConfig = useRuntimeConfig();
  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.biddingServer}/rest/bid/knock-down-complaint`;
  return usePostForFormData(url, params);
}

export function addComplaint(params: any) {
  const { usePostForFormData } = useHttpService();
  const runtimeConfig = useRuntimeConfig();
  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.biddingServer}/rest/bid/user-complaint`;
  return usePostForFormData(url, params);
}

export function fetchComplaintsTypes(itemId: number) {
  const { useGet } = useHttpService();
  const runtimeConfig = useRuntimeConfig();
  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.biddingServer}/rest/bid/user-complaint/permissions?itemId=${itemId}`;
  return useGet(url);
}
